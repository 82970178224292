import React, { useState } from "react";
import { Container, Row, Col, Badge } from "react-bootstrap";
import { PLSCard } from "../card.js";

function renderCard(id, revealedCards, showCardIdx, onClick) {
  return (
    <div onClick={() => onClick(id)}>
      <PLSCard
        selected={showCardIdx === id}
        title={revealedCards[id]["name"]}
        desc={revealedCards[id]["desc"]}
        iconUrl={revealedCards[id]["iconUrl"]}
      />
    </div>
  );
}

export function PickWinner(props) {
  const game = props.game;
  const [showCardIdx, updateCardIdx] = useState(null);

  function getCurrPlayerName(game) {
    let currPlayer = game.ctx.currentPlayer;
    let playerName = game.matchData[currPlayer]["name"];
    return playerName;
  }
  let currentPlayer = getCurrPlayerName(game);
  let relationshipTarget = game.G.relationshipString;

  function getPlayerNameForID(id) {
    let playerName = game.matchData[id]["name"];
    return playerName;
  }

  let revealedCards = game.G.revealedCards;

  // let selectedPlayer = null;
  // let selectedCard = null;

  // function selectCard(id, position) {
  //   selectedPlayer = id;
  //   console.log("selectedPlayer: ", selectedPlayer);
  //   selectedCard = game.G.revealedCards[id]["id"];
  //   console.log("selectedCard: ", selectedCard);
  //   console.log("position: ", position);
  //   //set "position" as active card
  // }

  return (
    <div>
      <Container style={{ marginTop: "4rem" }}>
        <Row>
          <Col>
            <h5>
              <Badge className="m-4 p-2" bg="primary">
                {currentPlayer} möchte die Beziehung zu {relationshipTarget}{" "}
                verbessern
              </Badge>
            </h5>
          </Col>
        </Row>
        <Row className="m-3 " style={{ height: "1rem" }}></Row>
        <Row>
          {Object.keys(revealedCards).map((p, i) => (
            <Col id={"col" + i}>
              <Badge className="m-3"> {getPlayerNameForID(p)}</Badge>
              {renderCard(p, revealedCards, showCardIdx, (id) =>
                updateCardIdx(id)
              )}
            </Col>
          ))}
        </Row>
        <Col className="m-5">
          <h4>Welche Karte passt am besten?</h4>
          <button
            className="m-3 btn btn-primary"
            onClick={() =>
              showCardIdx !== null &&
              game.moves.pickWinner(game.G.revealedCards[showCardIdx]["id"])
            }
          >
            Auswählen
          </button>
        </Col>
      </Container>
    </div>
  );
}
