import React from "react";
import {
  Container,
  Row,
  Col,
  Modal,
  Button,
  Nav,
  Tab,
  Tabs,
} from "react-bootstrap";

//Nutzungsbedigungen
function TermsofuseModal(props) {
  return (
    <Modal
      dialogClassName="terms-modal"
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Nutzungsbedingungen
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          <Row>
            <Col md="auto">
              <Nav variant="pills" className="flex-column">
                <Nav.Item>
                  <Nav.Link eventKey="first">Datennutzung</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="second">Einschränkungen</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="third">Anwendbares Recht</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="fourth">Benutzerverhalten</Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col>
              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <p>
                    Durch den Zugriff auf die PLS-Website erkl&auml;ren Sie sich
                    mit diesen Nutzungsbedingungen und allen geltenden Gesetzen
                    und Vorschriften sowie damit einverstanden, dass Sie
                    f&uuml;r deren Einhaltung verantwortlich sind.
                  </p>
                  <p>
                    Wenn Sie nicht einverstanden sind, ist es Ihnen untersagt,
                    auf das Spiel zuzugreifen.
                  </p>
                  <p>
                    Die im PLS enthaltenen Materialien sind durch geltendes
                    Urheberrecht und Markenrecht gesch&uuml;tzt.
                  </p>
                  <p>
                    Wir behalten uns das Recht vor, die allgemeinen
                    Gesch&auml;ftsbedingungen und Datenschutzbestimmungen
                    jederzeit zu &auml;ndern.
                  </p>
                  <p>
                    Ihre fortgesetzte Nutzung des Spiels nach dem
                    Ver&ouml;ffentlichen von &Auml;nderungen an dieser
                    Richtlinie gilt als Ihre Zustimmung zu diesen
                    &Auml;nderungen.
                  </p>
                </Tab.Pane>
                <Tab.Pane eventKey="second">
                  <p>
                    Das PLS oder seine Betreiber haften unter keinen
                    Umst&auml;nden f&uuml;r Sch&auml;den. Selbst dann nicht,
                    wenn der PLS-Betreiber oder ein autorisierter Vertreter des
                    Spiels m&uuml;ndlich oder schriftlich &uuml;ber die
                    M&ouml;glichkeit eines solchen Schadens informiert wurde.
                    Einschlie&szlig;lich, aber nicht beschr&auml;nkt auf:
                  </p>
                  <ul>
                    <li>Sch&auml;den durch Daten- oder Gewinnverlust;</li>
                    <li>
                      Sch&auml;den aufgrund der Unterbrechung
                      vor&uuml;bergehender oder dauerhafter Wartungsarbeiten
                      oder technischer Probleme;
                    </li>
                    <li>
                      Sch&auml;den, die durch die Verwendung oder
                      Unf&auml;higkeit entstehen, die Materialien in unserem
                      Spiel zu verwenden.
                    </li>
                  </ul>
                  <p>
                    Wir &uuml;bernehmen keine Verantwortung f&uuml;r externe
                    Links, die an Dritte gesendet werden. Die Aufnahme dieser
                    Links bedeutet nicht, dass wir das Material oder den Inhalt
                    dieser Websites genehmigen. Wir sind nicht verantwortlich
                    f&uuml;r Verluste, Sch&auml;den oder Unannehmlichkeiten, die
                    beim Spielen von PLS auftreten k&ouml;nnen.
                  </p>
                </Tab.Pane>
                <Tab.Pane eventKey="third">
                  <p>
                    Diese allgemeinen Geschäftsbedingungen unterliegen den
                    deutschen Gesetzen und werden entsprechend ausgelegt. Sie
                    unterliegen unwiderruflich der ausschließlichen
                    Zuständigkeit der Gerichte in Deutschland.
                  </p>
                </Tab.Pane>
                <Tab.Pane eventKey="fourth">
                  <p>
                    Sie sind verpflichtet alle anderen Benutzer mit Respekt
                    behandeln und alle aktualisierten Bedingungen und
                    Richtlinien zu erfüllen, die mit dem PLS verbunden sind.
                    Unangemessene Sprache und unangemessenes Verhalten sind
                    verboten. Wir sind nicht verantwortlich für
                    benutzergenerierte Inhalte wie Spitznamen. Die Benutzer sind
                    für ihre Handlungen und die daraus resultierenden
                    Konsequenzen verantwortlich.
                  </p>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-warning"
          className="ds-button-close"
          size="sm"
          onClick={props.onHide}
        >
          Schließen
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

//Datenschutz
function DataprivacyModal(props) {
  return (
    <Modal
      dialogClassName="privacy-modal"
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Datenschutzerklärung
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tabs
          defaultActiveKey="Datennutzung"
          transition={false}
          id="noanim-tab-example"
          className="mb-3"
          fill
          variant="tabs"
        >
          <Tab eventKey="Datennutzung" title="Datennutzung">
            <p>
              In dieser Datenschutzrichtlinie bedeutet &bdquo;Daten&ldquo; alle
              Daten, die wir zu Analysezwecken sammeln oder zur Bereitstellung
              der Dienste verarbeiten m&uuml;ssen.
            </p>
            <p>
              Daten, die wir m&ouml;glicherweise sammeln, wenn Sie unseren
              Dienst nutzen:
            </p>
            <ul>
              <li>
                Daten &uuml;ber Ihren Standort, Ihren Ger&auml;tetyp, Ihr
                Betriebssystem, die verwendete Plattform, die Version Ihres
                Computerbrowsers und Betriebssystems, die Seitenladezeit, das
                Netzwerk, die generierten Informationen &uuml;ber die
                Ger&auml;te-ID, die eindeutige Browser-ID, die Referenzquelle
                und die IP-Adresse;
              </li>
              <li>
                die Anzahl der Besuche unserer Dienste und die Zeit, die Sie mit
                den Diensten verbringen.
              </li>
            </ul>
            <p>Wir k&ouml;nnen Ihre Daten auf folgende Weisen nutzen:</p>
            <ul>
              <li>
                Um sicherzustellen, dass Sie unsere Bedingungen einhalten;
              </li>
              <li>
                Um die Dienste zu verbessern, Zwecke zu analysieren und zu
                melden und technischen Support bereitzustellen bzw. Ihre Fragen
                zu beantworten.
              </li>
            </ul>
            <p>
              Wir werden die Daten nur so lange aufbewahren, wie sie f&uuml;r
              den Zweck, f&uuml;r den sie urspr&uuml;nglich erhoben wurden,
              relevant und n&uuml;tzlich sind, und wenn dies gesetzlich
              vorgeschrieben ist.
            </p>
            <p>
              Die Daten, die wir von Ihnen werden innerhalb des
              Europ&auml;ischen Wirtschaftsraums (&bdquo;EWR&ldquo;)
              gespeichert. Dies ist erforderlich, um die Dienste zu hosten,
              Supportdienste bereitzustellen, Sicherungen Ihrer Daten
              durchzuf&uuml;hren und die Nutzung der Dienste zu
              erm&ouml;glichen.
            </p>
            <p>
              F&uuml;r die Bereitstellung der Dienste verwenden wir{" "}
              <a href="https://www.salesforce.com/content/dam/web/en_us/www/documents/legal/Agreements/data-processing-addendum.pdf">
                heroku
              </a>{" "}
              als Auftragsverarbeiter (Art. 28 DSGVO), die Daten in unserem
              Auftrag zu verarbeiten und diese nicht f&uuml;r unabh&auml;ngige
              Zwecke verwenden k&ouml;nnen.
            </p>
          </Tab>
          <Tab eventKey="Cookies" title="Cookies">
            <p>
              <p>
                Das PLS Game verwendet m&ouml;glicherweise
                &bdquo;Cookies&ldquo;, um die Benutzererfahrung zu verbessern.
                Diese Cookies sind wichtig, damit Ihr Ger&auml;t Informationen
                herunterladen oder &uuml;bertragen und die Dienste durchsuchen
                kann.
              </p>
              <p>
                Es folgen einige Beispiele daf&uuml;r, wie wir unbedingt
                notwendige Cookies verwenden:
              </p>
              <ul>
                <li>Um die Sitzung aktiv zu halten;</li>
                <li>
                  Um Ihnen das problemlose Besuchern der Dienste zu
                  erm&ouml;glichen;
                </li>
              </ul>
              <p>
                Der Benutzer kann seinen Webbrowser so konfigurieren, dass
                Cookies abgelehnt werden, oder Sie benachrichtigen, wenn sie
                gesendet werden. Beachten Sie in diesem Fall, dass einige Teile
                der Website m&ouml;glicherweise nicht ordnungsgem&auml;&szlig;
                funktionieren.
              </p>
            </p>
          </Tab>
          <Tab
            eventKey="Sonstige Rückstellungen"
            title="Sonstige Rückstellungen"
          >
            <p>
              Unsere Dienstleistungen richten sich nicht speziell an Kinder.
              Wenn Sie ein Benutz unter 16 Jahren sind, sind Sie nicht zur
              Nutzung der Dienste berechtigt.
            </p>
          </Tab>
        </Tabs>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-warning"
          className="ds-button-close"
          size="sm"
          onClick={props.onHide}
        >
          Schließen
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export function FooterSimple() {
  const [modal1Show, setModal1Show] = React.useState(false);
  const [modal2Show, setModal2Show] = React.useState(false);

  return (
    <Container
      id="footer-simple"
      style={{ fontSize: 9.5, textAlign: "center" }}
    >
      <Row>
        <Col></Col>
        <Col>
          <div style={{ fontSize: 11 }}> Ein Produkt von HBC</div>
        </Col>
        <Col className="text-end">
          <div>
            <button className="ds-button" onClick={() => setModal1Show(true)}>
              Nutzungsbedigungen
            </button>
            <TermsofuseModal
              show={modal1Show}
              onHide={() => setModal1Show(false)}
            />
          </div>
          <div>
            <button className="ds-button" onClick={() => setModal2Show(true)}>
              Datenschutz
            </button>
            <DataprivacyModal
              show={modal2Show}
              onHide={() => setModal2Show(false)}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
}

/**
 * Footer component
 * @param {bool} mobileOnly - only display on mobile devices, <768 px
 */
export default function Footer({ mobileOnly = false }) {
  return (
    <footer className={mobileOnly ? "d-block d-md-none" : null}>
      <Container style={{ fontSize: 10, textAlign: "center" }}>
        <div>Ein Produkt von HBC</div>
      </Container>
    </footer>
  );
}
