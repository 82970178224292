import React from "react";
import { Container, Row, Col, Badge, Carousel } from "react-bootstrap";

export function Lobby(props) {
  const game = props.game;
  let playerNames = [];

  function getNames() {
    playerNames = [];
    let playerOrder = game.G.orderArr;
    let matchData = game.matchData;

    for (let i = 0; i < matchData.length; i++) {
      let playerID = playerOrder[i];
      let playerName = matchData[playerID]["name"];
      playerNames.push(playerName);
    }
  }
  getNames();

  return (
    <div>
      <Container>
        <Row className="m5">
          <Col>
            <h1>Dein Team</h1>
            <Row className="m-5">
              {playerNames.map((p, i) => (
                <Col id={"col" + i}>
                  <Badge bg="secondary" pill="true" className="p-2">
                    {p}
                  </Badge>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>

        <Row>
          <Col style={{ height: "2rem" }}></Col>
        </Row>

        <Row className="m-5">
          <Col>
            <h3>Fallgeber Reihenfolge</h3>
            <div className="w-25" style={{ margin: "auto" }}>
              {playerNames.map((p, i) => (
                <p style={{ textAlign: "left" }}>
                  {i + 1}. {p}
                </p>
              ))}
            </div>
            <Col>
              <button
                className="btn btn-secondary"
                onClick={() => game.moves.goFirst(game.playerID)}
              >
                Erster Spieler werden
              </button>
            </Col>
          </Col>

          <Col>
            <h3>Positive Leadership Spiel</h3>
            <Carousel
              interval={4000}
              variant="dark"
              style={{ "min-height": "16rem", width: "120%" }}
            >
              <Carousel.Item>
                <Container>
                  <Row>
                    <Col>
                      <img
                        className="w-75"
                        id="sliderImage"
                        src="./assets/vector_PLS_lobby.svg"
                        alt="roles"
                      />
                    </Col>
                    <Col>
                      <Carousel.Caption>
                        <p>Zu Beginn des Spiels werden die Rollen verteilt.</p>
                        <br />
                        <p>
                          In jedem Spiel braucht es einen Fallgeber und mehrere
                          Berater. Bei mehreren Runden wechselt der Fallgeber
                          nach jeder Runde.
                        </p>
                      </Carousel.Caption>
                    </Col>
                  </Row>
                </Container>
              </Carousel.Item>
              <Carousel.Item>
                <Container>
                  <Row>
                    <Col>
                      <img
                        className="w-75"
                        id="sliderImage"
                        src="./assets/vector_PLSrelationship.svg"
                        alt="relationship"
                      />
                    </Col>
                    <Col>
                      <Carousel.Caption>
                        <p>
                          Der Fallgeber schildert seinen Fall auf Basis
                          folgender Frage: "welche Beziehung möchte ich
                          verbessern?"
                        </p>
                      </Carousel.Caption>
                    </Col>
                  </Row>
                </Container>
              </Carousel.Item>
              <Carousel.Item>
                <Container>
                  <Row>
                    <Col>
                      <img
                        className="w-75"
                        id="sliderImage"
                        src="./assets/vector_PLS_choosecard.svg"
                        alt="chooseCard"
                      />
                    </Col>
                    <Col>
                      <Carousel.Caption>
                        <p>
                          Jeder Berater wählt aus seinen 4 Positive Leadership
                          Strategien eine aus, die sie dem Fallgeber empfehlen.
                          Diese wird vorgestellt, aber nicht mit allen
                          diskutiert.
                        </p>
                      </Carousel.Caption>
                    </Col>
                  </Row>
                </Container>
              </Carousel.Item>
              <Carousel.Item>
                <Container>
                  <Row>
                    <Col>
                      <img
                        className="w-75"
                        id="sliderImage"
                        src="./assets/vector_PLSwinner.svg"
                        alt="win"
                      />
                    </Col>
                    <Col>
                      <Carousel.Caption>
                        <p>
                          Der Fallgeber zieht ein Fazit und wählt den Gewinner
                          anhand der Strategie, die ihm am besten gefällt.
                        </p>
                      </Carousel.Caption>
                    </Col>
                  </Row>
                </Container>
              </Carousel.Item>
            </Carousel>
          </Col>
        </Row>

        <Row className="p-2">
          <Col>
            <button
              className="btn btn-primary"
              onClick={() => game.moves.setReady(game.playerID)}
            >
              Bereit
            </button>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
