import { Card } from "react-bootstrap";
import React from "react";

export function PLSCard(props) {
  console.log(props.selected);
  return (
    <Card
      style={{
        background: "#8099D850",
        borderRadius: "12px",
        width: "16.5rem",
        height: "26.5rem",
        margin: "auto",
        marginTop: "1rem",
      }}
      className={props.selected ? "show" : ""}
    >
      <Card.Body>
        <Card.Title
          className="m-4 "
          style={{
            height: "3rem",
          }}
        >
          {props.title}
        </Card.Title>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              width: "7rem",
              height: "7rem",
              background: "#fff",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Card.Img variant="top" src={props.iconUrl} className="w-50" />
          </div>
        </div>
        <br />
        <Card.Text
          className="align-items-center"
          style={{ height: "137px", display: "flex", justifyContent: "center" }}
        >
          <small>{props.desc}</small>
        </Card.Text>
        <br />
      </Card.Body>
    </Card>
  );
}
