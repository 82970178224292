import React from "react";
import { Container, Row, Col, Badge } from "react-bootstrap";
import { PLSCard } from "../card.js";
import { PLSDummy } from "../cardDummy.js";

export function RevealCards(props) {
  const game = props.game;

  function getCurrPlayerName(game) {
    let currPlayer = game.ctx.currentPlayer;
    let playerName = game.matchData[currPlayer]["name"];
    return playerName;
  }
  let currentPlayer = getCurrPlayerName(game);
  let relationshipTarget = game.G.relationshipString;

  function getPlayerNameForID(id) {
    let playerName = game.matchData[id]["name"];
    return playerName;
  }

  let chosenCards = game.G.chosenCards;
  let revealedCards = game.G.revealedCards;

  function revealCard(id, position) {
    console.log("@click revealcard id", id);
    game.moves.revealCards(id);
  }

  let cardIdArr = Object.keys(revealedCards)
    .concat(Object.keys(chosenCards))
    .sort();
  console.log("cardIdArr", cardIdArr);

  return (
    <div>
      <Container
        className="justify-content-center"
        style={{ marginTop: "4rem" }}
      >
        {/* <Row>
          <Col>{currentPlayer}</Col>
        </Row> */}
        <Row>
          <Col>
            <h5>
              <Badge className="m-4 p-2" bg="primary">
                {currentPlayer} möchte die Beziehung zu {relationshipTarget}{" "}
                verbessern
              </Badge>
            </h5>
          </Col>
        </Row>
        <Row className="m-3" style={{ height: "1rem" }}>
          <Col>
            <small>
              <p>klicke auf die Karten, um sie umzudrehen</p>
            </small>
          </Col>
        </Row>
        <Row className="m-4 ">
          {cardIdArr.map((cardId, cardIndex) => (
            <Col id={"col" + cardIndex} onClick={() => revealCard(cardId)}>
              <Badge className="m-3"> {getPlayerNameForID(cardId)}</Badge>
              {!revealedCards[cardId] && <PLSDummy />}
              {revealedCards[cardId] && (
                <PLSCard
                  title={revealedCards[cardId]["name"]}
                  desc={revealedCards[cardId]["desc"]}
                  iconUrl={revealedCards[cardId]["iconUrl"]}
                />
              )}
            </Col>
          ))}
        </Row>
        <Col></Col>
      </Container>
    </div>
  );
}
