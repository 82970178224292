import React, { useState } from "react";
import { PLSCard } from "../card.js";

import { Container, Row, Col, Badge } from "react-bootstrap";

function renderCard(id, playerHand, showCardIdx, onClick) {
  return (
    <div onClick={() => onClick(id)}>
      <PLSCard
        selected={showCardIdx === id}
        title={playerHand[id]["name"]}
        desc={playerHand[id]["desc"]}
        iconUrl={playerHand[id]["iconUrl"]}
      />
    </div>
  );
}

function getCurrPlayerName(game) {
  let currPlayer = game.ctx.currentPlayer;
  let playerName = game.matchData[currPlayer]["name"];
  return playerName;
}

export function CardsRenewed(props) {
  const game = props.game;
  const [showCardIdx, updateCardIdx] = useState(null);

  let currentPlayer = getCurrPlayerName(game);
  let relationshipTarget = game.G.relationshipString;
  let playerHand = game.G.hands[game.playerID];

  return (
    <div>
      <Container style={{ marginTop: "4rem" }}>
        <Row>
          <Col>
            <h5>
              <Badge className="m-4 p-2" bg="primary">
                {currentPlayer} möchte die Beziehung zu {relationshipTarget}{" "}
                verbessern
              </Badge>
            </h5>
          </Col>
        </Row>
        <Row className="m-3 ">
          <Col>
            <p>
              Welche Positive Leadership Strategie kann {currentPlayer} dafür
              nutzen?
            </p>
          </Col>
        </Row>
        <Row className="m-2">
          <Col>
            {renderCard(0, playerHand, showCardIdx, (id) => updateCardIdx(id))}
          </Col>
          <Col>
            {renderCard(1, playerHand, showCardIdx, (id) => updateCardIdx(id))}
          </Col>
          <Col>
            {renderCard(2, playerHand, showCardIdx, (id) => updateCardIdx(id))}
          </Col>
          <Col>
            {renderCard(3, playerHand, showCardIdx, (id) => updateCardIdx(id))}
          </Col>
        </Row>
        <Row>
          <Col className="m-5">
            <button
              className="btn btn-primary"
              onClick={() =>
                showCardIdx !== null &&
                game.moves.pickCards(playerHand[showCardIdx]["id"])
              }
            >
              Auswählen
            </button>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
