import { Card } from "react-bootstrap";
import React from "react";

export function PLSDummy() {
  return (
    <Card
      style={{
        background: "#8099D850",
        borderRadius: "12px",
        width: "16.5rem",
        height: "26.5rem",
        margin: "auto",
        marginTop: "1rem",
      }}
    >
      <Card.Body></Card.Body>
    </Card>
  );
}
