import React from "react";
import { Button, Col, Container, Row, Badge, Stack } from "react-bootstrap";
import { PLSCard } from "../card";

function getCurrPlayerName(game) {
  let currPlayer = game.ctx.currentPlayer;
  let playerName = game.matchData[currPlayer]["name"];
  return playerName;
}

export function WinnerChosen(props) {
  const game = props.game;

  let winningCard = game.G.winningCard;
  let currentPlayer = getCurrPlayerName(game);
  let relationshipTarget = game.G.relationshipString;

  return (
    <Container style={{ marginTop: "4rem" }}>
      <h3 className="my-5">Die Karten sind gefallen!</h3>
      <Row className="justify-content-center align-items-center">
        <Col>
          <img src="./assets/vector_PLSwinner.svg" className="w-100" />
        </Col>
        <Col>
          {/* <Row>
            <h5>
              <Badge className="m-1 p-2" bg="primary">
                {currentPlayer} möchte die Beziehung zu {relationshipTarget}{" "}
                verbessern
              </Badge>
            </h5>
          </Row> */}
          <Row>
            <h5>Gewonnen hat</h5>
            <PLSCard
              title={winningCard.name}
              desc={winningCard.desc}
              iconUrl={winningCard.iconUrl}
            />
          </Row>
          <Row className="w-50 align-items-center mx-auto">
            <Stack gap={4} className="align-items-center m-3">
              <Button
                variant="primary"
                className="btn btn-primary"
                onClick={() => game.moves.goToLobby()}
              >
                Lobby
              </Button>
              <Button
                variant="primary"
                className="btn btn-primary"
                onClick={() => game.moves.endTurn()}
              >
                Nächste Runde
              </Button>
            </Stack>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
