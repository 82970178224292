import axios from 'axios';
import {Buzzer} from '../lib/store';
import {LobbyClient} from "boardgame.io/client";

axios.defaults.headers['Content-Type'] = 'application/json';
axios.defaults.headers['Accept'] = 'application/json';

const hostname = window.location.hostname;
const port = window.location.port;
const protocol = window.location.protocol;
const gameport = process.env.PORT || 4001;
const url = protocol + '//' + hostname + (port ? ':' + port : '');
const localUrl = `${protocol}//${hostname}:${gameport}`;

const LOBBY_SERVER = process.env.NODE_ENV === 'production' ? url : localUrl;
export const GAME_SERVER =
  process.env.NODE_ENV === 'production' ? url : localUrl;

export async function getMatch(matchId) {
  // convert to uppercase
  try {
    const lobbyClient = new LobbyClient({ server: GAME_SERVER });
    return await lobbyClient.getMatch(Buzzer.name, matchId);
  } catch (error) {
    if (error.response) {
      return error.response;
    } else {
      return { status: 500 };
    }
  }
}

export async function createMatch(numberPlayers) {
  try {
    const response = axios.post(`${LOBBY_SERVER}/games/${Buzzer.name}/create`, {
      numPlayers: numberPlayers || 5,
      unlisted: true,
    });
    return response;
  } catch (error) {
    if (error.response) {
      return error.response;
    } else {
      return { status: 500 };
    }
  }
}

export async function joinMatch(matchID, playerID, playerName) {
  try {
    const response = axios.post(
      `${LOBBY_SERVER}/games/${Buzzer.name}/${matchID}/join`,
      {
        playerID,
        playerName,
      }
    );
    return response;
  } catch (error) {
    if (error.response) {
      return error.response;
    } else {
      return { status: 500 };
    }
  }
}

export async function leaveMatch(matchID, playerID, credentials) {
  try {
    const response = axios.post(
      `${LOBBY_SERVER}/games/${Buzzer.name}/${matchID}/leave`,
      {
        playerID,
        credentials,
      }
    );
    return response;
  } catch (error) {
    if (error.response) {
      return error.response;
    } else {
      return { status: 500 };
    }
  }
}
