import React from "react";
import { Container, Row, Col, Badge } from "react-bootstrap";
import { PLSCard } from "../card.js";

export function WaitingForCase(props) {
  const game = props.game;

  function getCurrPlayerName(game) {
    let currPlayer = game.ctx.currentPlayer;
    let playerName = game.matchData[currPlayer]["name"];
    return playerName;
  }
  let currentPlayer = getCurrPlayerName(game);
  let relationshipTarget = game.G.relationshipString;
  let chosenCard = game.G.chosenCards[game.playerID];
  return (
    <div>
      <Container style={{ marginTop: "4rem" }}>
        <Row>
          <Col>
            <h5>
              <Badge className="m-4 p-2" bg="primary">
                {currentPlayer} möchte die Beziehung zu {relationshipTarget}{" "}
                verbessern
              </Badge>
            </h5>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>
              {" "}
              Welche Positive Leadership Strategie kann {currentPlayer} dafür
              nutzen?
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <PLSCard
              title={chosenCard["name"]}
              desc={chosenCard["desc"]}
              iconUrl={chosenCard["iconUrl"]}
            />
          </Col>
          <Col>
            <h4>Gute Wahl!</h4>
            <p>
              Warte noch einen kurzen Moment, bis alle eine Strategie gewählt
              haben
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <button className="btn btn-secondary" onClick={() => game.moves}>
              Ändern
            </button>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
