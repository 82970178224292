import { INVALID_MOVE, TurnOrder } from "boardgame.io/core";
import * as cardDeckLib from "./cardDeckLib.json";

function goFirst(G, ctx) {
  let firstPosition = [];
  firstPosition.push(ctx.playerID);
  let currentOrder = G.orderArr;
  let oldOrderCleaned = currentOrder.filter(function (item) {
    return item !== ctx.playerID;
  });
  let newOrder = firstPosition.concat(oldOrderCleaned);
  newOrder.splice(ctx.numPlayers);
  G.orderArr = newOrder;
}

function setReady(G, ctx) {
  ctx.events.setStage("lobbyIsReady");

  const playersReady = Object.values(ctx.activePlayers).filter(
    (p) => p === "lobbyIsReady"
  );

  if (playersReady.length === ctx.numPlayers - 1) {
    ctx.events.endPhase();
  }
}

function unready(G, ctx) {
  ctx.events.setStage("lobby");
}

function pickRelationshipGoal(G, ctx, goalString) {
  G.relationshipString = goalString;
  ctx.events.setActivePlayers({
    currentPlayer: "waitingForCards",
    others: "pickCards",
  });
}

function drawCards(newState, ctx) {
  const playersBefore = ctx.playOrder.slice(0, ctx.playOrderPos);
  const playersAfter = ctx.playOrder.slice(
    ctx.playOrderPos + 1,
    ctx.numPlayers
  );
  const shiftedOtherPlayers = playersAfter.concat(playersBefore);

  for (let p of shiftedOtherPlayers) {
    if (!newState.hands[p]) {
      newState.hands[p] = [];
    }
    // Players always get four new cards
    const numberOfMissingCards = 4 - newState.hands[p].length;
    console.log("cardDeck", newState.cardDeck);
    const newCards = newState.cardDeck.slice(0, numberOfMissingCards);

    newState.hands[p] = newState.hands[p].concat(newCards);
    newState.cardDeck = newState.cardDeck.slice(
      numberOfMissingCards,
      newState.cardDeck.length
    );
  }
}

function renewCards(G, ctx) {
  const newCards = G.cardDeck.slice(0, 4);
  G.cardDeck.splice(0, 4);
  const oldCards = G.hands[ctx.playerID];
  G.hands[ctx.playerID] = newCards;
  G.cardDeck = G.cardDeck.concat(oldCards);
  ctx.events.setStage("cardsRenewed");
}

function pickCards(G, ctx, cardId) {
  const card = G.hands[ctx.playerID].find((c) => c.id === cardId);
  if (!card) {
    return INVALID_MOVE;
  }
  G.chosenCards[ctx.playerID] = card;
  G.hands[ctx.playerID] = G.hands[ctx.playerID].filter((c) => c.id !== cardId);
  ctx.events.endStage();
  const cardChosenPlayers = Object.values(ctx.activePlayers).filter(
    (p) => p === "cardChosen"
  );
  // We need only numPlayer - 2 because of the currentPlayer as well as the player who just played his move.
  if (cardChosenPlayers.length === ctx.numPlayers - 2) {
    ctx.events.setActivePlayers({
      currentPlayer: "revealCards",
      others: "waitingForReveal",
    });
  }
}

function revealCards(G, ctx) {
  const playersBefore = ctx.playOrder.slice(0, ctx.playOrderPos);
  const playersAfter = ctx.playOrder.slice(
    ctx.playOrderPos + 1,
    ctx.numPlayers
  );
  const shiftedOtherPlayers = playersAfter.concat(playersBefore);

  let nextCard = null;
  for (const p of shiftedOtherPlayers) {
    nextCard = G.chosenCards[p];
    if (nextCard) {
      G.revealedCards[p] = nextCard;
      delete G.chosenCards[p];
      break;
    }
  }

  let hasMoreCards = null;
  for (const p of shiftedOtherPlayers) {
    hasMoreCards = G.chosenCards[p];
  }
  if (!hasMoreCards) {
    ctx.events.endStage();
  }
}

function pickWinner(G, ctx, cardId) {
  const card = Object.values(G.revealedCards).find((c) => c.id === cardId);
  if (!card) {
    return INVALID_MOVE;
  }
  G.winningCard = card;
  ctx.events.setActivePlayers({ all: "winnerChosen" });
}

function shuffleArray(a) {
  var j, x, i;
  for (i = a.length - 1; i > 0; i--) {
    j = Math.floor(Math.random() * (i + 1));
    x = a[i];
    a[i] = a[j];
    a[j] = x;
  }
  return a;
}

function endTurn(G, ctx) {
  ctx.events.endTurn();
}

function goToLobby(G, ctx) {
  ctx.events.setPhase("lobby");
}

function createNewState(ctx) {
  const cards = shuffleArray([...cardDeckLib.cards]);
  return {
    orderArr: ["0", "1", "2", "3", "4"],
    // cardDeck: cardDeckLib.cards,
    cardDeck: cards,
    hands: {},
    chosenCards: {},
    revealedCards: {},
    relationshipString: "",
    winningCard: null,
  };
}

export const Buzzer = {
  name: "pls-game",
  setup: (ctx) => createNewState(ctx),
  minPlayers: 3,
  maxPlayers: 5,

  phases: {
    lobby: {
      turn: {
        onBegin: (G, ctx) => {
          console.log("Starting turn in phase lobby.");
          ctx.events.setActivePlayers({ all: "lobby" });
        },
        order: TurnOrder.DEFAULT,
        stages: {
          lobby: { moves: { goFirst, setReady } },
          lobbyIsReady: { moves: { unready } },
        },
      },
      start: true,
      next: "game",
    },

    game: {
      next: "lobby",
      turn: {
        onBegin: (G, ctx) => {
          console.log("Starting new turn.");
          const newState = {
            ...createNewState(ctx),
            hands: { ...G.hands },
            cardDeck: G.cardDeck,
          };
          drawCards(newState, ctx);

          ctx.events.setActivePlayers({
            currentPlayer: "pickRelationshipGoal",
            others: "waitingRelationshipGoal",
          });
          return { ...newState };
        },

        order: TurnOrder.CUSTOM_FROM("orderArr"),

        // Stages represent different parts of one PLS round
        stages: {
          pickRelationshipGoal: {
            moves: { pickRelationshipGoal },
            next: "pickCards",
          },
          waitingRelationshipGoal: {},
          pickCards: {
            moves: { pickCards, renewCards },
            next: "cardChosen",
          },
          cardsRenewed: {
            moves: { pickCards },
            next: "cardChosen",
          },
          cardChosen: {},
          revealCards: {
            moves: { revealCards },
            next: "pickWinner",
          },
          pickWinner: {
            moves: { pickWinner },
          },
          winnerChosen: {
            moves: { endTurn, goToLobby },
          },
        },
      },
    },
  },
};
