import React from "react";
import { Navbar } from "react-bootstrap";
import { useHistory } from "react-router";
import { leaveMatch } from "../lib/endpoints";

export default function Header({ auth = {}, clearAuth }) {
  const history = useHistory();

  // leave current game
  async function leave() {
    try {
      await leaveMatch(auth.matchID, auth.playerID, auth.credentials);
      clearAuth();
      history.push("/");
    } catch (error) {
      clearAuth();
      history.push("/");
    }
  }

  return (
    <header>
      <Navbar
        style={{
          position: "absolute",
          width: "100%",
        }}
      >
        <Navbar.Brand>PLS Game</Navbar.Brand>

        <div className="nav-buttons">
          {clearAuth ? (
            <button
              className="btn btn-light"
              onClick={() => leave()}
              style={{ fontSize: "0.8rem", fontWeight: "200" }}
            >
              Spiel verlassen
            </button>
          ) : null}
        </div>
      </Navbar>
    </header>
  );
}
