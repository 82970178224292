import React, {useState} from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch,} from 'react-router-dom';
import {get, isNil} from 'lodash';

import Lobby from './containers/Lobby';
import Game from './containers/Game';
import './App.css';

function App() {
    const [auth, setAuth] = useState(JSON.parse(localStorage.getItem('pls-game-auth')) || {
        playerID: null,
        credentials: null,
        matchID: null,
    });
    React.useEffect(() => {
        localStorage.setItem('pls-game-auth', JSON.stringify(auth));
    }, [auth]);


    return (
        <div className="App">
            <Router>
                <Switch>
                    <Route
                        path="/:id"
                        render={({location, match}) => {
                            const matchID = get(match, 'params.id');
                            // redirect if the matchID in auth doesn't match, or no credentials
                            return matchID &&
                            auth.matchID === matchID &&
                            !isNil(auth.credentials) &&
                            !isNil(auth.playerID) ? (
                                <Game auth={auth} setAuth={setAuth}/>
                            ) : (
                                <Redirect
                                    to={{
                                        pathname: '/',
                                        state: {from: location, matchID},
                                    }}
                                />
                            );
                        }}
                    />
                    <Route path="/">
                        <Lobby setAuth={setAuth}/>
                    </Route>
                </Switch>
            </Router>
        </div>
    );
}

export default App;
