import React from "react";
import { Container, Row, Col, Badge } from "react-bootstrap";
import { PLSCard } from "../card.js";
import { PLSDummy } from "../cardDummy.js";

export function WaitingForReveal(props) {
  const game = props.game;

  function getCurrPlayerName(game) {
    let currPlayer = game.ctx.currentPlayer;
    let playerName = game.matchData[currPlayer]["name"];
    return playerName;
  }
  let currentPlayer = getCurrPlayerName(game);
  let relationshipTarget = game.G.relationshipString;

  function getPlayerNameForID(id) {
    let playerName = game.matchData[id]["name"];
    return playerName;
  }

  let chosenCards = game.G.chosenCards;

  let revealedCards = game.G.revealedCards;

  let combinedObjects = Object.keys(revealedCards).concat(
    Object.keys(chosenCards)
  );

  return (
    <div>
      <Container style={{ marginTop: "4rem" }}>
        <Row>
          <Col>
            <h5>
              <Badge className="m-4 p-2" bg="primary">
                {currentPlayer} möchte die Beziehung zu {relationshipTarget}{" "}
                verbessern
              </Badge>
            </h5>
          </Col>
        </Row>
        <Row className="m-3 " style={{ height: "1rem" }}></Row>
        <Row className="justify-content-center">
          {combinedObjects.map((p, i) => (
            <Col id={"col" + i}>
              <Badge className="m-3"> {getPlayerNameForID(p)}</Badge>
              {!revealedCards[p] && <PLSDummy />}
              {revealedCards[p] && (
                <PLSCard
                  title={revealedCards[p]["name"]}
                  desc={revealedCards[p]["desc"]}
                  iconUrl={revealedCards[p]["iconUrl"]}
                />
              )}
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
}
