import React from "react";
import { Container, Row, Col } from "react-bootstrap";

export function WaitingRelationshipGoal(props) {
  const game = props.game;

  return (
    <div>
      <Container style={{ marginTop: "4rem" }}>
        <Row>
          <Col className="m-3">
            <h4>Bitte warte kurz, während der Fallgeber alles vorbereitet</h4>
          </Col>
        </Row>
        <Row>
          <Col className="w-25">
            <img src="./assets/vector_PLSrelationship.svg" className="w-50" />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
