import React from "react";
import { Container, Row, Col, Badge } from "react-bootstrap";

export function PickRelationshipGoal(props) {
  const game = props.game;

  function getCurrPlayerName(game) {
    let currPlayer = game.ctx.currentPlayer;
    let playerName = game.matchData[currPlayer]["name"];
    return playerName;
  }
  let currentPlayer = getCurrPlayerName(game);

  const handleSubmit = (e) => {
    e.preventDefault();
    game.moves.pickRelationshipGoal(document.getElementById("relTarget").value);
  };

  const handleKeypress = (e) => {
    if (e.keyCode === 13) {
      console.log("pressed enter!");
      handleSubmit();
    }
  };

  return (
    <div>
      <Container style={{ marginTop: "4rem" }}>
        <Row>
          <Col className="my-3" md={{ span: 6, offset: 3 }}>
            <h5>
              Schreibe bitte den Namen oder die Position der Person zu der du
              deine Beziehung verbessern möchtest.
            </h5>
          </Col>
        </Row>
        <Row>
          <Col className="w-25">
            <img src="./assets/vector_PLSrelationship.svg" className="w-25" />
          </Col>
        </Row>
        <Row className="m-5">
          <Col className="w-100 d-flex justify-content-center align-items-center">
            <br />
            <p>"{currentPlayer} möchte die Beziehung zu </p>
            <input type="text" id="relTarget" onKeyPress={handleKeypress} />
            <p> verbessern. </p>
          </Col>
        </Row>
        <button
          id="myBtn"
          className="btn btn-primary"
          onClick={() =>
            game.moves.pickRelationshipGoal(
              document.getElementById("relTarget").value
            )
          }
        >
          Weiter
        </button>
      </Container>
    </div>
  );
}
