import React from "react";
import { Container } from "react-bootstrap";
import Header from "../components/Header";
import { PickRelationshipGoal } from "./stages/pickRelationshipGoal";
import { PickCards } from "./stages/pickCards";
import { CardsRenewed } from "./stages/cardsRenewed";
import { CardsChosen } from "./stages/cardsChosen";
import { PickWinner } from "./stages/pickWinner";
import { WinnerChosen } from "./stages/winnerChosen";
import { RevealCards } from "./stages/revealCards";
import { WaitingForCase } from "./stages/waitingForCase";
import { WaitingForCards } from "./stages/waitingForCards";
import { WaitingRelationshipGoal } from "./stages/waitingRelationshipGoal";
import { Lobby } from "./stages/lobby";
import { LobbyIsReady } from "./stages/lobbyIsReady";
import { WaitingForReveal } from "./stages/waitingForReveal";

const STAGES = {
  lobby: Lobby,
  lobbyIsReady: LobbyIsReady,
  pickRelationshipGoal: PickRelationshipGoal,
  waitingRelationshipGoal: WaitingRelationshipGoal,
  waitingForCase: WaitingForCase,
  waitingForReveal: WaitingForReveal,
  pickCards: PickCards,
  waitingForCards: WaitingForCards,
  cardsRenewed: CardsRenewed,
  cardChosen: CardsChosen,
  revealCards: RevealCards,
  pickWinner: PickWinner,
  winnerChosen: WinnerChosen,
};

function waitingForOthers() {
  return <div>Warten auf andere...</div>;
}

export default function Board(game) {
  const players = !game.gameMetadata
    ? []
    : game.gameMetadata
        .filter((p) => p.name)
        .map((p) => ({ ...p, id: String(p.id) }));

  if (!game.ctx.activePlayers) {
    return waitingForOthers(game.G, game.ctx);
  }
  console.log(game);
  let activeStage = game.ctx.activePlayers[game.playerID];
  let CurrentComponent = STAGES[activeStage] || waitingForOthers;

  return (
    <div>
      <Header
        auth={game.headerData}
        clearAuth={() =>
          game.headerData.setAuth({
            playerID: null,
            credentials: null,
            matchID: null,
          })
        }
      />
      <Container fluid>
        <section
          style={{
            margin: "5px",
            fontSize: "0.7rem",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <p id="match-title">Raum {game.matchID}</p>
          {!game.isConnected ? (
            <p className="warning">
              Verbindung getrennt - versuche erneut zu verbinden...
            </p>
          ) : null}
        </section>
        <section>
          <CurrentComponent game={game} />
        </section>
      </Container>
    </div>
  );
}
