import React, { useState } from "react";
import { Container, Form, Row } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { get } from "lodash";
import { createMatch, getMatch, joinMatch } from "../lib/endpoints";
import { copyTextToClipboard } from "../lib/clipboard";
import Header from "../components/Header";
import Footer, { FooterSimple } from "../components/Footer";

const ERROR_TYPE = {
  emptyCode: "emptyCode",
  matchCode: "matchCode",
  password: "password",
  name: "name",
  hostMatch: "hostMatch",
  fullMatch: "fullMatch",
  numPlayers: "numPlayers",
  dupName: "dupName",
};

const ERROR_MESSAGE = {
  [ERROR_TYPE.emptyCode]: "Bitte gib einen Raumcode ein",
  [ERROR_TYPE.numPlayers]: "Anzahl Spieler ungültig",
  [ERROR_TYPE.matchCode]: "Raum nicht gefunden",
  [ERROR_TYPE.password]: "Falsches Passwort",
  [ERROR_TYPE.name]: "Name ungültig",
  [ERROR_TYPE.dupName]: "Name bereits vergeben",
  [ERROR_TYPE.hostMatch]: "Konnte keinen Raum erstellen",
  [ERROR_TYPE.fullMatch]: "Dieser Raum ist voll",
};

export default function Lobby({ setAuth }) {
  const location = useLocation();
  const prefilledMatchID = get(location, "state.matchID");

  const history = useHistory();
  const [password, setPassword] = useState("");
  const [numberPlayers, setNumberPlayers] = useState(5);
  const [name, setName] = useState("");
  const [match, setMatch] = useState(prefilledMatchID || "");
  const [joinMode, setJoinMode] = useState(true);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  // enter match: find match, then join it
  async function enterMatch(matchId, hosting = false) {
    if (!hosting) {
      setLoading(true);
    }

    try {
      // get match
      const match = await getMatch(matchId);
      if (!match) {
        throw new Error(ERROR_TYPE.matchCode);
      }
      // determine seat to take
      const playerSeat = match.players.find((player) => player.name === name);
      const freeSeat = match.players.find((player) => !player.name);

      if (playerSeat && playerSeat.connected) {
        throw new Error(ERROR_TYPE.dupName);
      }
      if (!playerSeat && !freeSeat) {
        throw new Error(ERROR_TYPE.fullMatch);
      }
      const playerID = get(playerSeat, "id", get(freeSeat, "id"));
      const joinRes = await joinMatch(match.matchID, playerID, name);
      if (joinRes.status !== 200) {
        throw new Error(ERROR_TYPE.matchCode);
      }
      const creds = joinRes.data;
      const auth = {
        playerID,
        credentials: creds.playerCredentials,
        matchID: match.matchID,
      };

      // save auth and go to match
      setAuth(auth);
      setLoading(false);
      history.push(`/${match.matchID}`);
    } catch (error) {
      setLoading(false);
      setError(ERROR_MESSAGE[error.message]);
    }
  }

  // make match: create match, then join it
  async function makeMatch() {
    setLoading(true);
    try {
      if (numberPlayers < 3 || numberPlayers > 5) {
        throw new Error(ERROR_TYPE.numPlayers);
      }

      const createRes = await createMatch(numberPlayers);
      if (createRes.status !== 200) {
        throw new Error(ERROR_TYPE.hostMatch);
      }
      const matchID = createRes.data.matchID;
      setMatch(matchID);
    } catch (error) {
      setLoading(false);
      setError(ERROR_MESSAGE[error.message]);
    }
  }

  function copyMatchLink(event) {
    event.preventDefault();
    copyTextToClipboard(
      window.location.protocol + "//" + window.location.host + "/" + match
    );
  }

  function handleSubmit(event) {
    event.preventDefault();

    // validate match and/or player password has been filled
    if (joinMode) {
      if (match.trim().length === 0) {
        setError(ERROR_MESSAGE[ERROR_TYPE.emptyCode]);
      } else if (name.trim().length === 0) {
        setError(ERROR_MESSAGE[ERROR_TYPE.name]);
      } else {
        enterMatch(match);
      }
    } else {
      if (
        password !== "SECRET" &&
        password !== "goodVibes" &&
        password !== "gluecksRitter" &&
        password !== "thriveUp" &&
        password !== "flowHigh" &&
        password !== "emotionsKanone" &&
        password !== "freudenTanz" &&
        password !== "sinnErleben" &&
        password !== "eudaemonia" &&
        password !== "strongMindset" &&
        password !== "flourishNow"
      ) {
        setError(ERROR_MESSAGE[ERROR_TYPE.password]);
      } else {
        makeMatch();
      }
    }
  }

  const form = joinMode ? (
    <Form className="lobby-form" onSubmit={(e) => handleSubmit(e)}>
      <h3>Spiel beitreten</h3>
      <Form.Group controlId="match">
        <Form.Label>Raumcode</Form.Label>
        <Form.Control
          value={match}
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="characters"
          spellCheck="false"
          onChange={(e) => {
            setError("");
            setMatch(e.target.value);
          }}
        />
      </Form.Group>

      <Form.Group controlId="name">
        <Form.Label>Dein Name</Form.Label>
        <Form.Control
          value={name}
          onChange={(e) => {
            setError("");
            setName(e.target.value);
          }}
        />
      </Form.Group>

      <div className="error-message">{error}</div>
      <button type="submit" disabled={loading}>
        {loading ? "Beitreten..." : "Beitreten"}
      </button>
      <div className="switcher">
        Bist du ein Administrator?{" "}
        <button
          className="inline"
          onClick={() => {
            setError("");
            setMatch();
            setJoinMode(false);
          }}
        >
          Raum anlegen
        </button>
      </div>
    </Form>
  ) : match ? (
    <Form className="lobby-form">
      <h3>Raumlink kopieren</h3>
      <Form.Group controlId="matchlink">
        <Form.Label>Raum-Link</Form.Label>
        <Form.Control
          disabled={true}
          value={
            window.location.protocol + "//" + window.location.host + "/" + match
          }
          onChange={(e) => {
            setError("");
            setPassword(e.target.value);
          }}
        />
      </Form.Group>
      <button className="inline" onClick={copyMatchLink}>
        Kopieren
      </button>

      <div className="switcher">
        Stattdessen Raum beitreten?{" "}
        <button
          className="inline"
          onClick={() => {
            setError("");
            setJoinMode(true);
          }}
        >
          Raum beitreten
        </button>
      </div>
    </Form>
  ) : (
    <Form className="lobby-form" onSubmit={(e) => handleSubmit(e)}>
      <h3>Raum anlegen</h3>
      <Form.Group controlId="password">
        <Form.Label>Administrator-Passwort</Form.Label>
        <Form.Control
          value={password}
          onChange={(e) => {
            setError("");
            setPassword(e.target.value);
          }}
        />
        <Form.Label>Anzahl Spieler (3-5)</Form.Label>
        <Form.Control
          type="number"
          value={numberPlayers}
          onChange={(e) => {
            setError("");
            setNumberPlayers(e.target.value);
          }}
        />
      </Form.Group>
      <div className="error-message">{error}</div>
      <button type="submit" disabled={loading}>
        {loading ? "Anlegen..." : "Raum anlegen"}
      </button>
      <div className="switcher">
        Stattdessen Raum beitreten?{" "}
        <button
          className="inline"
          onClick={() => {
            setError("");
            setJoinMode(true);
          }}
        >
          Raum beitreten
        </button>
      </div>
    </Form>
  );

  const touts = (
    <div className="touts w-100">
      <div>
        {/* <h4>PLS Game</h4>
        <p>Hier ist Platz für eine freie Beschreibung</p> */}
        <img alt="" className="w-100 m-3" src="./assets/Vector_PLS_team.svg" />
      </div>
    </div>
  );

  return (
    <main id="lobby">
      <div class="d-flex justify-content-center m-5">
        <Container>
          <Row>
            <h1 className="d-flex justify-content-center">
              Positive Leadership
            </h1>
          </Row>
          <Row>
            <h2 className="d-flex justify-content-center">Das Kartenspiel</h2>
          </Row>
        </Container>
      </div>
      <section className=" d-none d-md-flex  w-100">
        <div id="lobby-left">
          <div>
            <Header />
            <section className="container-half">{touts}</section>
          </div>
          <section className="container-half">
            <FooterSimple />
          </section>
        </div>
        <div id="lobby-right">
          <section className="container-half">{form}</section>
        </div>
      </section>

      {/* /*Mobile optimized input Form - temporary disabled */}
      <section className="primary d-block d-md-none">
        <Header />
        <Container className="container-mobile">{touts}</Container>
        <div className="divider" />
        <Container className="container-mobile">{form}</Container>
      </section>
      <Footer mobileOnly />
    </main>
  );
}
