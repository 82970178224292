import React from "react";
import { Container, Row, Col } from "react-bootstrap";

export function WaitingForCards(props) {
  const game = props.game;

  return (
    <div>
      <Container
        className="justify-content-center"
        style={{ marginTop: "4rem" }}
      >
        <Row>
          <Col className="m-3">
            <h4>
              Warte einen kurzen Moment während deine Mitspieler ihre
              Spielstrategie auswählen.
            </h4>
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <img src="./assets/vector_PLS_Fallgeber.svg" className="w-50" />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
